/*!

 =========================================================
 * Material Dashboard PRO Angular - v2.6.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-angular2
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
 @import "~@angular/material/prebuilt-themes/indigo-pink.css";

 @import "core/variables";
 @import "core/mixins";
 @import "~bootstrap/scss/bootstrap";
 @import "core/reboot";

// Core Components
@import "core/layout";
@import "core/buttons";
@import "core/checkboxes";
@import "dashboard/cards";
@import "core/custom-forms";
@import "core/switches";
@import "core/radios";
@import "core/carousel";
@import "dashboard/forms";
@import "core/input-group";
@import "core/list-group";
@import "core/nav";
@import "core/images";
@import "dashboard/navbar";
@import "core/badges";
@import "dashboard/alerts";
@import "core/pagination";
@import "core/pills";
@import "core/info-areas";
@import "core/type";
@import "core/tabs";
@import "core/footers";
@import "core/tooltip";
@import "core/popover";
@import "core/modal";
@import "core/dropdown";
@import "core/headers";
@import "core/drawer";
@import "core/progress";
@import "core/togglebutton";
@import "core/ripples";
@import "dashboard/rtl";
@import "dashboard/sidebar-and-main-panel";
@import "dashboard/timeline";
@import "dashboard/tables";
@import "dashboard/misc";
@import "dashboard/pages";

 // Components for PRO
 @import "dashboard/social-buttons";
 @import "dashboard/fileupload";

 //plugin css
 @import "plugins/animate";
 @import "plugins/sweetalert2";
 @import "plugins/datatables.net";
 @import "plugins/jquery.jvectormap";
 @import "plugins/plugin-datetime-picker";
 @import "plugins/wizard-card";
 @import "plugins/select-bootstrap";
 @import "plugins/perfect-scrollbar";
 @import "plugins/plugin-tagsinput";

@import "dashboard/responsive";

/* make svgs scrollable / content scrollable on mobile devices */
svg, g, path {
    touch-action: pan-y;
}

.btn.btn-just-icon .material-icons.large {
    font-size: 32px;
}

tr.child td.child span.dtr-data {
    display: flex;
    justify-content: space-around;
}

table.dataTable .child .dtr-details {
    width: 100%;
}

@media screen and (max-width: 640px) {
    app-available-links .card .toolbar {
        text-align: center !important;
    }
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: $brand-primary;
}

.overlay-streched {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@media (min-width: 992px){
    .show-mobile-menu {
        display: none;
    }
}