@use "sass:math";

/* material icons ------------------------------------------------------------------------------ */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('../../../fonts/material-icons/material-icons.woff2') format('woff2');
}
  
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* ci fonts ------------------------------------------------------------------------------------ */

@font-face {
    font-family: 'qualy';
    font-style: normal;
    font-weight: 400;
    src: url('../../../fonts/qualy/Qualy.ttf') format('ttf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../../../fonts/montserrat/Montserrat-Regular.otf') format('otf');
}

@font-face {
    font-family: 'Montserrat';
    font-style: bold;
    font-weight: 700;
    src: url('../../../fonts/montserrat/Montserrat-Bold.otf') format('otf');
}

@font-face {
    font-family: 'Montserrat Alternates';
    font-style: normal;
    font-weight: normal;
    src: url('../../../fonts/montserrat-alternates/MontserratAlternates-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'Montserrat Alternates';
    font-style: bold;
    font-weight: bold;
    src: url('../../../fonts/montserrat-alternates/MontserratAlternates-Bold.ttf') format('ttf');
}



// Typography
//
// Font, line-height, and color for body text, headings, and more.

// https://www.google.com/design/spec/style/typography.html#typography-styles
// http://www.getmdl.io/styles/index.html

$font-family-sans-serif: 'Montserrat Alternates', 'Helvetica', 'Arial', sans-serif !default;
$font-family-serif:      'Monserat', 'Times New Roman', serif !default;
//$font-family-monospace:      Menlo, Monaco, Consolas, "Courier New", monospace !default;
//$font-family-base:           $font-family-sans-serif !default;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
//$font-size-root:             16px !default;
//
$font-size-base:             1rem !default;
//$font-size-lg:               1.25rem !default;
//$font-size-sm:               .875rem !default;
//$font-size-xs:               .75rem !default;
//

$font-size-h1:                 3.3125rem;
$font-size-h2:                 2.25rem !default;
$font-size-h3:                 1.5625rem;
$font-size-h4:                 1.125rem !default;
$font-size-h5:                 1.0625rem !default;
$font-size-h6:                 0.75rem !default;
$font-paragraph:               14px !default;
$font-size-navbar:             16px !default;
$font-size-small:              12px !default;


//
$display1-size: 7rem !default; // md display-4 112px was 6rem;
$display2-size: 3.5rem !default; // md display-3 56px was 5.5rem
$display3-size: 2.8125rem !default; // md display-2 45px was 4.5rem
$display4-size: 2.125rem !default; // md display-1 34px was 3.5rem
//
//$display1-weight:             300 !default;
//$display2-weight:             300 !default;
//$display3-weight:             300 !default;
//$display4-weight:             300 !default;
//
//$line-height-base:                1.5 !default;
//
$headings-margin-bottom:       math.div($spacer, 2) !default;
//$headings-font-family:       inherit !default;
$headings-font-weight:         400 !default; // was 500

$font-weight-light: 300 !default;
$font-weight-default: 400 !default;
$font-weight-bold:    500 !default;
$font-weight-extra-bold: 700 !default;

$font-size-large:              1em          !default;
$font-size-large-navbar:       20px         !default;

//$headings-line-height:       1.1 !default;
//$headings-color:             inherit !default;
//
//$lead-font-size:             1.25rem !default;
//$lead-font-weight:           300 !default;
//
//$text-muted:                  $gray-light !default;
//
//$abbr-border-color:           $gray-light !default;
//
//$blockquote-small-color:      $gray-light !default;
//$blockquote-font-size:        ($font-size-base * 1.25) !default;
//$blockquote-border-color:     $gray-lighter !default;
//
//$hr-border-color:             rgba(0,0,0,.1) !default;
//$hr-border-width:             $border-width !default;
//
//$list-inline-padding:         5px !default;
//
//$dt-font-weight:              bold !default;
//
//$nested-kbd-font-weight:      bold !default;

$padding-input-vertical:        11px !default;
$padding-input-horizontal:      19px !default;

$padding-btn-vertical:         11px !default;
$padding-btn-horizontal:       22px !default;

$padding-base-vertical:        .5rem !default;
$padding-base-horizontal:      .7rem !default;

$padding-round-horizontal:     23px !default;

$padding-simple-vertical:      10px !default;
$padding-simple-horizontal:    17px !default;

$padding-large-vertical:       15px !default;
$padding-large-horizontal:     48px !default;

$padding-small-vertical:        5px !default;
$padding-small-horizontal:     15px !default;

$padding-label-vertical:        2px !default;
$padding-label-horizontal:     12px !default;

$margin-large-vertical:        30px !default;
$margin-base-vertical:         15px !default;

$margin-base-horizontal:       15px !default;
