body{
    background-color: white;
    background-image: url('../../img/pattern-gray.png');
    color: $black-color;
    font-weight: 300;
}

legend {
  border-bottom: 0;
}

.serif-font{
    font-family: $font-family-serif;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
  }
}

a{
  color: $link-color;
  &:hover,
  &:focus{
      color: darken($link-color, 5%);
      text-decoration: none;
  }

  &.text-info{
      &:hover, &:focus{
          color: darken($brand-info, 5%);
      }
  }

  & .material-icons {
    vertical-align: middle;
  }
}

.main{
    background: #FFFFFF;
    position: relative;
    z-index: 3;
}

.form-check,
label{
    font-size: 14px;
    line-height: 1.42857;
    color: $checkboxes-text-color;
    font-weight: 400;
}

.main-raised{
    margin: -60px 30px 0px;
    border-radius: $border-radius-base * 2;
    @include shadow-16dp();
}

/*           Animations              */
.animation-transition-general{
    @include transition-all($general-transition-time, $transition-linear);
}

.animation-transition-slow{
    @include transition-all($slow-transition-time, $transition-linear);
}

.animation-transition-fast{
    @include transition-all($fast-transition-time, $transition-ease);
}


.animation-transition-fast,
.navbar,
.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role="remove"],
.card-collapse .card-header a i{
    @include transitions($fast-transition-time, $transition-ease);
}

.signup-page{
  .card-signup{
    form{
      .form-check{
        padding-left: 20px;

        .form-check-label{
          padding-left: 35px;
        }
      }
    }
  }
}

// style for index.html (free version)

.section-signup{
  .card-signup{
    .card-header{
      width: auto;
    }
    .card-body{
      .input-group{
        padding-bottom: 7px;
        margin: 27px 0 0 0;
      }
    }
  }
}


// offline-doc
.offline-doc,
.offline-free-doc {
  .page-header {
    height: 100vh !important;

    &:after {
      background: rgba(0, 0, 0, 0.5) !important;
    }
  }
  .footer {
    position: absolute;
    width: 100%;
    background: transparent;
    bottom: 0;
    color: $white-color;
    z-index: 1;

    .copyright a{
      color: $white-color;
    }
  }

  .navbar {
    .navbar-brand,
    .navbar-collapse .nav-link {
      color: $white-color;
    }
  }
}


// hinge card



@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%, 80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.tim-row{
    margin-bottom: 20px;
}

.tim-white-buttons {
    background-color: #777777;
}
.title{
    margin-top: 30px;
    margin-bottom: 25px;
    min-height: 32px;
}

#map {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(100vh - 70px);
    margin-top: 70px;
}

.tim-typo{
    padding-left: 25%;
    margin-bottom: 40px;
    position: relative;
    width: 100%;
}
.tim-typo .tim-note{
    bottom: 5px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
}
.tim-row{
    padding-top: 50px;
}
.tim-row h3{
    margin-top: 0;
}
#typography h1, #typography h2, #typography h3,
#typography h4, #typography h5, #typography h6{
    margin-bottom: 0;
}

.switch{
    margin-right: 20px;
}
#navbar-full .navbar{
    border-radius: 0 !important;
    margin-bottom: 15px;
    z-index: 2;
}
.space{
    height: 130px;
    display: block;
}
.space-110{
    height: 110px;
    display: block;
}
.space-50{
    height: 50px;
    display: block;
}
.space-70{
    height: 70px;
    display: block;
}
.navigation-example .img-src{
    background-attachment: scroll;
}

.navigation-example{
    background-position: center center;
    background-size: cover;
    margin-top:0;
    min-height: 740px;
}
#notifications{
    background-color: $white-color;
    display: block;
    width: 100%;
    position: relative;
}
#notifications .alert-danger{
    margin-bottom: 0px;
}
.tim-note{
    text-transform: capitalize;
}

#buttons .btn{
    margin: 0 0px 15px;
}
.space-100{
    height: 100px;
    display: block;
    width: 100%;
}

  // PARALLAX

.parallax{
  width:100%;
  height:570px;

  display: block;
  background-attachment: fixed;
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center center;

}
.parallax .parallax-image{
    width: 100%;
    overflow: hidden;
    position: absolute;
}
.parallax .parallax-image img{
    width: 100%;
}

@media (max-width: 768px){
    .parallax .parallax-image{
         width: 100%;
         height: 640px;
         overflow: hidden;
     }
    .parallax .parallax-image img{
       height: 100%;
       width: auto;
   }
}

.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray{
    background-color: #EEEEEE;
}
.social-buttons-demo .btn{
  margin: 10px 5px 7px 1px;
}

.img-container{
    width: 100%;
    overflow: hidden;
}
.img-container img{
    width: 100%;
}

.section-black {
  background-color: #333;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.sharing-area{
    margin-top: 80px;
}
.sharing-area .btn{
    margin: 15px 4px 0;
    color: #FFFFFF;
}
.sharing-area .btn i{
    font-size: 18px;
    position: relative;
    top: 2px;
    margin-right: 5px;
}
#navbar{
    margin-bottom: -20px;
}
.sharing-area .btn-twitter{
    background-color: #55acee;
}
.sharing-area .btn-facebook{
    background-color: #3b5998;
}
.sharing-area .btn-google-plus{
    background-color: #dd4b39;
}
.sharing-area .btn-github{
    background-color: #333333;
}
#navbar .navbar{
    border-radius: 0;
}

@media (max-width: 830px){
    .main-raised{
        margin-left: 10px;
        margin-right: 10px;
    }
}
