/* You can add global styles to this file, and also import other style files */

.form-error-message {
    font-size: 90% !important;
    position: relative;
    top: -10px;
}

i.material-icons.info-icon {
    cursor: help;
}