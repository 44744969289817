// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

 .tooltip-inner, .mat-tooltip {
    color: $gray !important;
    line-height: 1.5em;
    background: $white-color;
    border: none;
    border-radius: $border-radius-base !important;
    @include shadow-8dp();
}

.tooltip, .tooltip.in {
  //opacity: 1;
}
.tooltip.in{
    opacity: 1;
    @include transform-translate-y(0px);
}
.tooltip{
    opacity: 0;
    transition: opacity, transform .2s ease;
    @include transform-translate-y(5px);

    &.left{
        .tooltip-arrow{
            border-left-color: $white-color;
        }
    }
    &.right{
        .tooltip-arrow{
            border-right-color: $white-color;
        }
    }
    &.top{
        .tooltip-arrow{
            border-top-color: $white-color;
        }
    }
    &.bottom{
        .tooltip-arrow{
            border-bottom-color: $white-color;
        }
    }
}

.tooltip-inner, .mat-tooltip{
    padding: 10px 15px;
    min-width: 130px;
}

.mat-tooltip {
    text-align: center;
    font-weight: bolder;
    font-size: 14px !important;
}
